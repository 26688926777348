import {ActivatedRouteSnapshot, Route} from "@angular/router";

export function disableHelp(route: Route): Route {
  return {
    ...route,
    data: {
      __display_help: false
    }
  };
}

export function isHelpDisabled(activatedRoute: ActivatedRouteSnapshot | null): boolean {
  if (activatedRoute == null)
    return false;

  return activatedRoute.data['__display_help'] === false || activatedRoute.children.some(c => isHelpDisabled(c));
}
